<template>
  <custom-table
    :loading="loading"
    :title="$t('booking.activities.title')"
    :headers="[
      { text: $t('sysUser.name', locale), value: 'ident' },
      { text: '', value: 'actions', align: 'end' },
    ]"
    :tableData="items"
    :startIndex="startIndex"
    :count="count"
    :itemsPerPage="itemsPerPage"
    :onGetData="handleGetData"
    :onEditItem="handleEditItem"
    :onSearch="handleSearch"
  />
</template>
<script>
import utils from '@/services/utils'
import api from '@/services/api'
import CustomTable from '@/components/customTable/Index'
export default {
  components: {
    CustomTable,
  },
  props: {
    onEdit: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    updateData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    startIndex: 0,
    itemsPerPage: 5,
    search: '',
    items: [],
    count: 0,
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('booking')
    },
  },
  watch: {
    updateData (v) {
      if (v.action === 'add') {
        this.items.unshift(this.prepareData(v.data))
        this.count++
      } else if (v.action === 'update') {
        this.getItem(v.data)
      }
      alert(v.action)
      console.log(v)
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    prepareData (v) {
      v.Name = utils.getTranslation(v.Name, this.locale)
      return v
    },
    getData () {
      this.loading = true
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/activities`, this.startIndex, this.itemsPerPage, this.search ? this.search : '')
        .then(response => {
          this.count = response.count
          this.items = response.data.map(item => {
            return this.prepareData(item)
          })
          this.loading = false
        })
    },
    getItem (v) {
      this.loading = true
      api.getItem ('booking', `v1/private/activities/`, v)
        .then(response => {
          this.items.filter(x => x.ID === v)[0] = this.prepareData(response)
          this.loading = false
        })
    },
    handleSearch(v) {
      this.search = v
      this.startIndex = 0
      this.getData()
    },
    handleEditItem(v) {
      this.onEdit(v ? v : 'new')
    },
    handleGetData(v) {
      this.startIndex = v
      this.getData()
    },
  }
}
</script>

